import Cookies from 'universal-cookie';

export async function fetchPatient(id) {
  const cookies = new Cookies();
  const response = await fetch(
    `http://ec2-3-17-200-114.us-east-2.compute.amazonaws.com/api/patients/${id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': cookies.get('session-token'),
      },
      credentials: 'include',
    }
  );
  const data = await response.json();
  const status = await response.status;
  return {
    patient: data,
    status: status,
  };
}

export async function fetchPatients(name) {
  const cookies = new Cookies();
  var url;
  if (name === '') {
    url = `http://ec2-3-17-200-114.us-east-2.compute.amazonaws.com/api/patients`;
  } else {
    url = `http://ec2-3-17-200-114.us-east-2.compute.amazonaws.com/api/patients?name=${name}`;
  }
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Token': cookies.get('session-token'),
    },
    credentials: 'include',
  });
  const data = await response.json();
  const status = await response.status;
  return {
    patients: data,
    status: status,
  };
}

export async function createPatient(patient) {
  const cookies = new Cookies();
  const response = await fetch(
    `http://ec2-3-17-200-114.us-east-2.compute.amazonaws.com/api/patients`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': cookies.get('session-token'),
      },
      credentials: 'include',
      body: JSON.stringify(patient),
    }
  );
  const data = await response.json();
  const status = await response.status;
  return {
    patients: data,
    status: status,
  };
}

export async function updatePatient({ id, patient }) {
  const cookies = new Cookies();
  const response = await fetch(
    `http://ec2-3-17-200-114.us-east-2.compute.amazonaws.com/api/patients/${id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': cookies.get('session-token'),
      },
      credentials: 'include',
      body: JSON.stringify(patient),
    }
  );
  const data = await response.json();
  const status = await response.status;
  return {
    patients: data,
    status: status,
  };
}

export async function deletePatient({ patient_id }) {
  const cookies = new Cookies();
  const response = await fetch(
    `http://ec2-3-17-200-114.us-east-2.compute.amazonaws.com/api/patients/${patient_id}`,
    {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'X-Auth-Token': cookies.get('session-token'),
      },
    }
  );
  const status = await response.status;
  return {
    status: status,
  };
}
