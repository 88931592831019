import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPatient, updatePatient } from '../integrations/Patients';
import { uploadImage } from '../integrations/Images';
import PropTypes from 'prop-types';

function PatientForm({ patient, edit }) {
  const verb = edit ? 'Editar ' : 'Novo ';
  const [id, setId] = useState(0);
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [prefered_name, setPreferedName] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [comercial_telephone, setComercialTelephone] = useState('');
  const [gender, setGender] = useState('');
  const [date_of_birth, setDateOfBirth] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [treatment, setTreatment] = useState('');
  const [observations, setObservations] = useState('');
  const [history, setHistory] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!edit) {
      return;
    }
    var birthDate = '';
    if (patient.date_of_birth) {
      birthDate = new Date(patient.date_of_birth).toISOString().split('T')[0];
    }
    setId(patient.id);
    setCode(patient.code);
    setName(patient.name);
    setPreferedName(patient.prefered_name);
    setEmail(patient.email);
    setTelephone(patient.telephone);
    setComercialTelephone(patient.comercial_telephone);
    setGender(patient.gender);
    setDateOfBirth(birthDate);
    setStreet(patient.street);
    setNumber(patient.number);
    setComplement(patient.complement);
    setNeighborhood(patient.neighborhood);
    setZipcode(patient.zipcode);
    setCity(patient.city);
    setState(patient.state);
    setTreatment(patient.treatment);
    setHistory(patient.history);
    setObservations(patient.observations);
  }, [patient]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (edit) {
      try {
        let newPatient = {
          name,
          prefered_name,
          email,
          telephone,
          comercial_telephone,
          gender,
          street,
          complement,
          neighborhood,
          zipcode,
          city,
          state,
          treatment,
          observations,
          history,
        };
        if (date_of_birth != '') {
          newPatient.date_of_birth = new Date(date_of_birth).toISOString();
        }
        if (code != '') {
          newPatient.code = parseInt(code);
        }
        if (number != '') {
          newPatient.number = parseInt(number);
        }

        let res = await updatePatient({ id, patient: newPatient });
        if (res.status === 200) {
          console.log('Patient updated');
          if (selectedImage) {
            try {
              let imageRes = await uploadImage({
                image: selectedImage,
                patient_id: res.patients.id,
              });
              if (imageRes.status === 200) {
                console.log('Image uploaded');
              } else {
                console.log('Some error occured');
              }
            } catch (error) {
              console.log(error);
            }
          }
          navigate(`/patients/${res.patients.id}`);
        } else {
          console.log('Some error occured');
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        let newPatient = {
          name,
          prefered_name,
          email,
          telephone,
          comercial_telephone,
          gender,
          street,
          complement,
          neighborhood,
          zipcode,
          city,
          state,
          treatment,
          observations,
          history,
        };
        if (date_of_birth != '') {
          newPatient.date_of_birth = new Date(date_of_birth).toISOString();
        }
        if (code != '') {
          newPatient.code = parseInt(code);
        }
        if (number != '') {
          newPatient.number = parseInt(number);
        }

        let res = await createPatient(newPatient);
        if (res.status === 201) {
          console.log('Patient created');
          if (selectedImage) {
            try {
              let imageRes = await uploadImage({
                image: selectedImage,
                patient_id: res.patients.id,
              });
              if (imageRes.status === 200) {
                console.log('Image uploaded');
              } else {
                console.log('Some error occured');
              }
            } catch (error) {
              console.log(error);
            }
          }
          navigate(`/patients/${res.patients.id}`);
        } else {
          console.log('Some error occured');
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className='container'>
      <div className='text-title'>{verb}Paciente</div>
      <form onSubmit={handleSubmit}>
        <div className='input-data'>
          <label htmlFor=''>Code</label>
          <input
            type='text'
            name='code'
            onChange={(event) => setCode(event.target.value)}
            value={code}
          />
        </div>

        <div className='input-data'>
          <label htmlFor=''>Foto</label>
          <input
            type='file'
            onChange={(e) => setSelectedImage(e.target.files[0])}
          />
        </div>

        <div className='input-data'>
          <label htmlFor=''>Nome Completo</label>
          <input
            type='text'
            name='name'
            onChange={(event) => setName(event.target.value)}
            value={name}
          />
        </div>
        <div className='input-data'>
          <label htmlFor=''>Chamar por</label>
          <input
            type='text'
            name='prefered_name'
            onChange={(event) => setPreferedName(event.target.value)}
            value={prefered_name}
          />
        </div>

        <div className='input-data'>
          <label htmlFor=''>E-mail</label>
          <input
            type='text'
            name='email'
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />
        </div>
        <div className='input-parent'>
          <div className='input-child'>
            <label htmlFor=''>Telefone</label>
            <input
              type='text'
              name='telephone'
              onChange={(event) => setTelephone(event.target.value)}
              value={telephone}
            />
          </div>
          <div className='input-child'>
            <label htmlFor=''>Telefone Comercial</label>
            <input
              type='text'
              name='comercial_telephone'
              onChange={(event) => setComercialTelephone(event.target.value)}
              value={comercial_telephone}
            />
          </div>
        </div>
        <div className='input-parent'>
          <div className='input-child' name='gender'>
            <label htmlFor=''>Gênero</label>
            <select
              name='gender'
              id='gender'
              onChange={(event) => setGender(event.target.value)}
              value={gender}
            >
              <option disabled selected value=''>
                {' '}
                Selecione{' '}
              </option>
              <option value='male'>Masculino</option>
              <option value='female'>Feminino</option>
            </select>
          </div>
          <div className='input-child' name='date_of_birth'>
            <label htmlFor=''>Data de Nascimento</label>
            <input
              type='date'
              name='date_of_birth'
              onChange={(event) => setDateOfBirth(event.target.value)}
              value={date_of_birth}
            />
          </div>
        </div>
        <br />
        <div className='input-data' name='street'>
          <label htmlFor=''>Endereço</label>
          <input
            type='text'
            name='street'
            onChange={(event) => setStreet(event.target.value)}
            value={street}
          />
        </div>
        <div className='input-parent'>
          <div className='input-child' name='number'>
            <label htmlFor=''>Número</label>
            <input
              type='text'
              name='number'
              onChange={(event) => setNumber(event.target.value)}
              value={number}
            />
          </div>
          <div className='input-child' name='complement'>
            <label htmlFor=''>Complemento</label>
            <input
              type='text'
              name='complement'
              onChange={(event) => setComplement(event.target.value)}
              value={complement}
            />
          </div>
        </div>
        <div className='input-parent'>
          <div className='input-child' name='neighborhood'>
            <label htmlFor=''>Bairro</label>
            <input
              type='text'
              name='neighborhood'
              onChange={(event) => setNeighborhood(event.target.value)}
              value={neighborhood}
            />
          </div>
          <div className='input-child' name='zipcode'>
            <label htmlFor=''>CEP</label>
            <input
              type='text'
              name='zipcode'
              onChange={(event) => setZipcode(event.target.value)}
              value={zipcode}
            />
          </div>
        </div>
        <div className='input-parent'>
          <div className='input-child' name='city'>
            <label htmlFor=''>Cidade</label>
            <input
              type='text'
              name='city'
              onChange={(event) => setCity(event.target.value)}
              value={city}
            />
          </div>
          <div className='input-child' name='state'>
            <label htmlFor=''>Estado</label>
            <select
              name='state'
              id='state'
              onChange={(event) => setState(event.target.value)}
              value={state}
            >
              <option disabled selected value=''>
                {' '}
                Selecione{' '}
              </option>
              <option value='AC'>Acre</option>
              <option value='AL'>Alagoas</option>
              <option value='AP'>Amapá</option>
              <option value='AM'>Amazonas</option>
              <option value='BA'>Bahia</option>
              <option value='CE'>Ceará</option>
              <option value='DF'>Distrito Federal</option>
              <option value='ES'>Espírito Santo</option>
              <option value='GO'>Goiás</option>
              <option value='MA'>Maranhão</option>
              <option value='MT'>Mato Grosso</option>
              <option value='MS'>Mato Grosso do Sul</option>
              <option value='MG'>Minas Gerais</option>
              <option value='PA'>Pará</option>
              <option value='PB'>Paraíba</option>
              <option value='PR'>Paraná</option>
              <option value='PE'>Pernambuco</option>
              <option value='PI'>Piauí</option>
              <option value='RJ'>Rio de Janeiro</option>
              <option value='RN'>Rio Grande do Norte</option>
              <option value='RS'>Rio Grande do Sul</option>
              <option value='RO'>Rondônia</option>
              <option value='RR'>Roraima</option>
              <option value='SC'>Santa Catarina</option>
              <option value='SP'>São Paulo</option>
              <option value='SE'>Sergipe</option>
              <option value='TO'>Tocantins</option>
              <option value='EX'>Estrangeiro</option>
            </select>
          </div>
        </div>
        <br />
        <div className='input-data' name='treatment'>
          <label htmlFor=''>Histórico</label>
          <textarea
            rows='4'
            name='history'
            onChange={(event) => setHistory(event.target.value)}
            value={history}
          ></textarea>
        </div>
        <div className='input-data' name='treatment'>
          <label htmlFor=''>Tratamento</label>
          <textarea
            rows='4'
            name='treatment'
            onChange={(event) => setTreatment(event.target.value)}
            value={treatment}
          ></textarea>
        </div>
        <div className='input-data' name='obervations'>
          <label htmlFor=''>Observações</label>
          <textarea
            rows='4'
            name='obervations'
            onChange={(event) => setObservations(event.target.value)}
            value={observations}
          ></textarea>
        </div>
        <button className='btn btn-primary' type='submit'>
          {edit ? 'Atualizar' : 'Criar'}
        </button>
      </form>
    </div>
  );
}

PatientForm.propTypes = {
  patient: PropTypes.object,
  edit: PropTypes.bool,
};

PatientForm.defaultProps = {
  patient: {},
  edit: false,
};

export default PatientForm;
