import { useState } from 'react';
import { login } from '../integrations/Auth';
import { useNavigate } from 'react-router-dom';

function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await login({ username, password });
      console.log('login success');
      navigate('/');
    } catch (error) {
      console.log('login error', error);
    }
  };

  return (
    <main className='form-signin w-100 m-auto'>
      <form onSubmit={handleSubmit}>
        <h1 className='h3 mb-3 fw-normal'>Faça Seu Login</h1>

        <div className='form-floating'>
          <input
            type='text'
            className='formcontrol user-input'
            id='floatingInput'
            placeholder='Usuário'
            onChange={(event) => setUsername(event.target.value)}
          />
        </div>
        <div className='form-floating'>
          <input
            type='password'
            className='form-control password-input'
            id='floatingPassword'
            placeholder='Password'
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <button className='btn btn-primary login-btn' type='submit'>
          Entrar
        </button>
      </form>
    </main>
  );
}

export default LoginForm;
