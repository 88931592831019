import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchPatients } from '../integrations/Patients';
import { FaEdit } from 'react-icons/fa';
import PatientLink from './PatientLink';

function PatientsList() {
  const [patients, setPatients] = useState([]);
  const [status, setStatus] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    async function getData() {
      const patientsFromServer = await fetchPatients('');
      setPatients(patientsFromServer ? patientsFromServer.patients : []);
      setStatus(patientsFromServer.status);
    }
    getData();
  }, []);

  if (status === 404) {
    return <h1>Pacientes não encontrados</h1>;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let res = await fetchPatients(search);
      setPatients(res ? res.patients : []);
      setStatus(res.status);
    } catch (error) {
      console.log(error);
    }
  };

  const checkDate = (date_string) => {
    if (date_string === undefined || date_string === null) {
      return '';
    } else {
      let dat = new Date(date_string);
      dat.setDate(dat.getDate() + 1);
      return dat.toLocaleDateString('pt-br');
    }
  };

  return (
    <div className='patients-list'>
      <h1 className='list-title'>Pacientes</h1>
      <form onSubmit={handleSubmit}>
        <div className='input-data name-search'>
          <label htmlFor=''>Buscar por nome</label>
          <input
            type='text'
            name='search'
            onChange={(event) => setSearch(event.target.value)}
          />
        </div>
        <button className='btn btn-primary search-btn' type='submit'>
          Buscar
        </button>
      </form>
      <table className='table patient-list'>
        <thead className='table-header'>
          <tr>
            <th className='header__item'>Nome</th>
            <th className='header__item' align='right'>
              Data de Nascimento
            </th>
            <th className='header__item' align='right'>
              E-mail
            </th>
            <th className='header__item' align='right'>
              Telefone
            </th>
            <th className='header__item' align='right'>
              Editar
            </th>
          </tr>
        </thead>
        <tbody className='table-content'>
          {patients.map((patient) => (
            <tr key={patient.id}>
              <td className='table-data'>
                <Link to={`/patients/${patient.id}`}>
                  <PatientLink text={patient.name} />
                </Link>
              </td>
              <td className='table-data' align='right'>
                {checkDate(patient.date_of_birth)}
              </td>
              <td className='table-data' align='right'>
                {patient.email}
              </td>
              <td className='table-data' align='right'>
                {patient.telephone}
              </td>
              <td className='table-data' align='right'>
                <Link to={`/patients/${patient.id}/edit`}>
                  <FaEdit color='black' />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Link to={`/patients/new`}>
        <button className='btn btn-primary new-patient-btn'>
          Novo Paciente
        </button>
      </Link>
    </div>
  );
}

export default PatientsList;
