import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  createAppointment,
  updateAppointment,
} from '../integrations/Appointments';

function AppointmentForm({ patient_id, appointment, edit }) {
  const verb = edit ? 'Editar ' : 'Novo ';
  const [appointmentID, setAppointmentID] = useState(0);
  const [patientID] = useState(patient_id);
  const [professional, setProfessional] = useState('');
  const [treatment, setTreatment] = useState('');
  const [value, setValue] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (!edit) {
      return;
    }
    var datetime = '';
    if (appointment.datetime) {
      const dateObj = new Date(appointment.datetime);
      datetime = dateObj.toLocaleString();
      const dateArray = datetime.split(', ')[0].split('/');
      setDate(dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0]);
      setTime(datetime.split(', ')[1].slice(0, 5));
    }
    setAppointmentID(appointment.id);
    setProfessional(appointment.professional);
    setTreatment(appointment.treatment);
    setValue(appointment.value);
  }, [appointment]);

  const handleSubmit = async (event) => {
    let appDate = '';
    event.preventDefault();
    if (edit) {
      try {
        if (time === '') {
          appDate = new Date(`${date} 00:00:00 GMT-3:00`).toISOString();
        } else {
          appDate = new Date(`${date} ${time}:00 GMT-3:00`).toISOString();
        }
        let newAppointment = {
          datetime: appDate,
          professional,
          treatment,
        };
        if (value != '') {
          newAppointment.value = parseInt(value);
        }

        let res = await updateAppointment({
          patient_id: patientID,
          appointment_id: appointmentID,
          appointment: newAppointment,
        });
        if (res.status === 200) {
          console.log('Appointment updated');
          navigate(`/patients/${patientID}`);
        } else {
          console.log('Some error occured');
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        if (time === '') {
          appDate = new Date(`${date} 00:00:00 GMT-3:00`).toISOString();
        } else {
          appDate = new Date(`${date} ${time}:00 GMT-3:00`).toISOString();
        }
        let newAppointment = {
          datetime: appDate,
          professional,
          treatment,
        };
        if (value != '') {
          newAppointment.value = parseInt(value);
        }

        let res = await createAppointment({
          patient_id: patientID,
          appointment: newAppointment,
        });
        if (res.status === 201) {
          console.log('Appointment created');
          navigate(`/patients/${patientID}`);
        } else {
          console.log('Some error occured');
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className='container'>
      <div className='text-title'>{verb}Agendamento</div>
      <form onSubmit={handleSubmit}>
        <div className='input-parent'>
          <div className='input-child'>
            <label htmlFor=''>Profissional</label>
            <input
              type='text'
              name='professional'
              onChange={(event) => setProfessional(event.target.value)}
              value={professional}
            />
          </div>
          <div className='input-child' name='value'>
            <label htmlFor=''>Valor</label>
            <input
              type='text'
              name='value'
              onChange={(event) => setValue(event.target.value)}
              value={value}
            />
          </div>
        </div>
        <div className='input-parent'>
          <div className='input-child' name='date'>
            <label htmlFor=''>Data</label>
            <input
              type='date'
              name='date'
              onChange={(event) => setDate(event.target.value)}
              value={date}
            />
          </div>
          <div className='input-child' name='time'>
            <label htmlFor=''>Hora</label>
            <input
              type='time'
              name='time'
              onChange={(event) => setTime(event.target.value)}
              value={time}
            />
          </div>
        </div>
        <div className='input-data' name='treatment'>
          <label htmlFor=''>Tratamento</label>
          <textarea
            rows='4'
            name='treatment'
            onChange={(event) => setTreatment(event.target.value)}
            value={treatment}
          ></textarea>
        </div>
        <button className='btn btn-primary' type='submit'>
          {edit ? 'Atualizar' : 'Criar'}
        </button>
      </form>
    </div>
  );
}

AppointmentForm.propTypes = {
  appointment: PropTypes.object,
  edit: PropTypes.bool,
  patient_id: PropTypes.string.isRequired,
};

AppointmentForm.defaultProps = {
  appointment: {},
  edit: false,
};

export default AppointmentForm;
