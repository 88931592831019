import { useState, useEffect } from 'react';
import { FaEdit } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { fetchPatient } from '../integrations/Patients';
import { fetchAppointmentsForPatient } from '../integrations/Appointments';
import { getImage } from '../integrations/Images';
import { Link } from 'react-router-dom';

function PatientDetails({ patient_id }) {
  const [patientID] = useState(patient_id);
  const [patient, setPatient] = useState({});
  const [appointments, setAppointments] = useState([]);
  const [status, setStatus] = useState(0);
  const [image, setImage] = useState(null);
  const [imageStatus, setImageStatus] = useState(0);

  useEffect(() => {
    async function getData() {
      const patientFromServer = await fetchPatient(patientID);
      const appointmentsFromSarver = await fetchAppointmentsForPatient(
        patientID
      );
      setPatient(patientFromServer ? patientFromServer.patient : {});
      setStatus(patientFromServer.status);
      setAppointments(appointmentsFromSarver ? appointmentsFromSarver : []);

      const imageResponse = await getImage(patientID);
      setImageStatus(imageResponse.status);
      setImage(imageResponse ? imageResponse.image : null);
    }
    getData();
  }, []);

  const checkDate = (date_string) => {
    if (date_string === undefined || date_string === null) {
      return '';
    } else {
      return new Date(date_string).toLocaleDateString('pt-br');
    }
  };

  const checkBirthDate = (date_string) => {
    if (date_string === undefined || date_string === null) {
      return '';
    } else {
      let dat = new Date(date_string);
      dat.setDate(dat.getDate() + 1);
      return dat.toLocaleDateString('pt-br');
    }
  };

  const checkTime = (time_string) => {
    if (time_string === undefined || time_string === null) {
      return '';
    } else {
      let res = new Date(time_string).toLocaleTimeString('pt-br', {
        hour: '2-digit',
        minute: '2-digit',
      });
      if (res === '00:00') {
        return '';
      } else {
        return res;
      }
    }
  };

  const formatAddress = (patient) => {
    if (patient === undefined || patient === null) {
      return '';
    } else {
      let res = '';
      if (patient.street != '') {
        res = res.concat(patient.street, ',');
      }
      if (patient.number != 0) {
        res = res.concat(' ', patient.number, ',');
      }
      if (patient.complement != '') {
        res = res.concat(' ', patient.complement, ',');
      }
      if (patient.neighborhood != '') {
        res = res.concat(' ', patient.neighborhood, ',');
      }
      if (patient.city != '') {
        res = res.concat(' ', patient.city, ',');
      }
      if (patient.state != '') {
        res = res.concat(' ', patient.state, ',');
      }
      if (patient.zipcode != '') {
        res = res.concat(' ', patient.zipcode, ',');
      }
      res = res.slice(0, -1);
      return res;
    }
  };

  if (status === 404) {
    return <h1>Paciente não encontrado</h1>;
  }

  return (
    <div>
      <div className='patient-details'>
        {imageStatus === 200 && (
          <div className='picture'>
            <img className='picture' src={image} alt='icons' />
          </div>
        )}

        <h1>{patient.name}</h1>
        <p>
          <b>E-mail:</b> {patient.email}
        </p>
        <p>
          <b>Telefone:</b> {patient.telephone}
        </p>
        <p>
          <b>Data de nascimento:</b> {checkBirthDate(patient.date_of_birth)}
        </p>
        <p>
          <b>Endereço:</b> {formatAddress(patient)}
        </p>
        <p>
          <b>Histórico:</b> {patient.history}
        </p>
        <p>
          <b>Tratamento:</b> {patient.treatment}
        </p>
        <p>
          <b>Obs:</b> {patient.observations}
        </p>
        <h2>Consultas</h2>
        <table className='table'>
          <thead className='table-header'>
            <tr>
              <th className='header__item'>Data</th>
              <th className='header__item'>Hora</th>
              <th className='header__item'>Profissional</th>
              <th className='header__item'>Tratamento</th>
              <th className='header__item'>Valor</th>
              <th className='header__item'>Editar</th>
            </tr>
          </thead>
          <tbody className='table-content'>
            {appointments.map((appointment) => (
              <tr key={appointment.id}>
                <td className='table-data'>
                  {checkDate(appointment.datetime)}
                </td>
                <td className='table-data'>
                  {checkTime(appointment.datetime)}
                </td>
                <td className='table-data'>{appointment.professional}</td>
                <td className='table-data'>{appointment.treatment}</td>
                <td className='table-data'>
                  {appointment.value.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </td>
                <td className='table-data'>
                  <Link
                    to={`/patients/${patient.id}/appointments/${appointment.id}/edit`}
                    className='edit'
                  >
                    <FaEdit color='black' />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Link to={`/patients/${patient.id}/appointments/new`}>
          <button className='btn btn-primary new-appointment-btn'>
            Novo Agendamento
          </button>
        </Link>
      </div>
      <div>
        <Link to={`/patients`}>
          <h3 className='back-btn'>Voltar à lista</h3>
        </Link>
      </div>
    </div>
  );
}

PatientDetails.propTypes = {
  patient_id: PropTypes.string.isRequired,
};

export default PatientDetails;
