import Cookies from 'universal-cookie';

export async function uploadImage({ image, patient_id }) {
  const cookies = new Cookies();
  var data = new FormData();
  data.append('picture', image);
  const response = await fetch(
    `http://ec2-3-17-200-114.us-east-2.compute.amazonaws.com/api/images/${patient_id}`,
    {
      method: 'POST',
      credentials: 'include',
      body: data,
      headers: {
        'X-Auth-Token': cookies.get('session-token'),
      },
    }
  );
  const status = await response.status;
  return {
    status: status,
  };
}

export async function getImage(patient_id) {
  const cookies = new Cookies();
  const response = await fetch(
    `http://ec2-3-17-200-114.us-east-2.compute.amazonaws.com/api/images/${patient_id}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'X-Auth-Token': cookies.get('session-token'),
      },
    }
  );
  const status = await response.status;
  const imageBlob = await response.blob();
  const imageObjectURL = URL.createObjectURL(imageBlob);
  return {
    image: imageObjectURL,
    status: status,
  };
}
