import { useParams, useNavigate } from 'react-router-dom';
import PatientDetails from '../components/PatientDetails';
import { useEffect } from 'react';
import { checkLogin } from '../integrations/Auth';

function PatientDetailsPage() {
  const navigate = useNavigate();

  useEffect(() => {
    async function isLoggedIn() {
      const res = await checkLogin();
      if (!res.is_authorized) {
        navigate('/login');
      }
    }
    isLoggedIn();
  }, []);

  const { patient_id } = useParams();

  return <PatientDetails patient_id={patient_id} />;
}

export default PatientDetailsPage;
