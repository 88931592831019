import PropTypes from 'prop-types';

function PatientLink({ text }) {
  return <div>{text}</div>;
}

PatientLink.propTypes = {
  text: PropTypes.string.isRequired,
};

export default PatientLink;
