import Cookies from 'universal-cookie';

export async function fetchAppointmentsForPatient(patientId) {
  const cookies = new Cookies();
  const response = await fetch(
    `http://ec2-3-17-200-114.us-east-2.compute.amazonaws.com/api/patients/${patientId}/appointments`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': cookies.get('session-token'),
      },
      credentials: 'include',
    }
  );
  const data = await response.json();
  return data;
}

export async function createAppointment({ patient_id, appointment }) {
  const cookies = new Cookies();
  const response = await fetch(
    `http://ec2-3-17-200-114.us-east-2.compute.amazonaws.com/api/patients/${patient_id}/appointments`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': cookies.get('session-token'),
      },
      credentials: 'include',
      body: JSON.stringify(appointment),
    }
  );
  const data = await response.json();
  const status = await response.status;
  return {
    appointment: data,
    status: status,
  };
}

export async function updateAppointment({
  patient_id,
  appointment_id,
  appointment,
}) {
  const cookies = new Cookies();
  const response = await fetch(
    `http://ec2-3-17-200-114.us-east-2.compute.amazonaws.com/api/patients/${patient_id}/appointments/${appointment_id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': cookies.get('session-token'),
      },
      credentials: 'include',
      body: JSON.stringify(appointment),
    }
  );
  const data = await response.json();
  const status = await response.status;
  return {
    appointment: data,
    status: status,
  };
}

export async function getAppointment({ patient_id, appointment_id }) {
  const cookies = new Cookies();
  const response = await fetch(
    `http://ec2-3-17-200-114.us-east-2.compute.amazonaws.com/api/patients/${patient_id}/appointments/${appointment_id}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'X-Auth-Token': cookies.get('session-token'),
      },
    }
  );
  const data = await response.json();
  const status = await response.status;
  return {
    appointment: data,
    status: status,
  };
}

export async function getNextAppointments() {
  const cookies = new Cookies();
  const response = await fetch(
    `http://ec2-3-17-200-114.us-east-2.compute.amazonaws.com/api/appointments/next`,
    {
      method: 'GET',
      headers: {
        'X-Auth-Token': cookies.get('session-token'),
      },
    }
  );
  const data = await response.json();
  const status = await response.status;
  return {
    appointments: data,
    status: status,
  };
}
