import LoginForm from '../components/LoginForm';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkLogin } from '../integrations/Auth';

function LoginPage() {
  const navigate = useNavigate();
  useEffect(() => {
    async function isLoggedIn() {
      const res = await checkLogin();
      if (res.is_authorized) {
        navigate('/');
      }
    }
    isLoggedIn();
  }, []);
  return <LoginForm />;
}

export default LoginPage;
