import PatientsList from '../components/PatientsList';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { checkLogin } from '../integrations/Auth';

function PatientsListPage() {
  const navigate = useNavigate();

  useEffect(() => {
    async function isLoggedIn() {
      const res = await checkLogin();
      if (!res.is_authorized) {
        navigate('/login');
      }
    }
    isLoggedIn();
  }, []);

  return <PatientsList />;
}

export default PatientsListPage;
