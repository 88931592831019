import Cookies from 'universal-cookie';

export async function login({ username, password }) {
  const response = await fetch(
    'http://ec2-3-17-200-114.us-east-2.compute.amazonaws.com/auth/login',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ username, password }),
    }
  );
  const status = await response.status;
  const data = await response.json();

  const cookies = new Cookies();
  const current = new Date();
  cookies.set('session-token', data, {
    path: '/',
    expires: new Date(current.getTime() + 86400000),
  });

  return {
    data: data,
    status: status,
  };
}

export async function checkLogin() {
  const res = await verify();
  const status = await res.status;
  if (status != 200) {
    return {
      username: '',
      is_authorized: false,
    };
  }
  return {
    username: res.data.username,
    is_authorized: true,
  };
}

async function verify() {
  const cookies = new Cookies();
  const response = await fetch(
    'http://ec2-3-17-200-114.us-east-2.compute.amazonaws.com/auth/verify',
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'X-Auth-Token': cookies.get('session-token'),
      },
    }
  );
  const status = await response.status;
  const data = await response.json();
  return {
    data: data,
    status: status,
  };
}

export async function logout() {
  await fetch(
    'http://ec2-3-17-200-114.us-east-2.compute.amazonaws.com/auth/logout',
    {
      method: 'POST',
      credentials: 'include',
    }
  );
  const cookies = new Cookies();
  cookies.set('session-token', '', {
    path: '/',
    maxAge: -1,
  });
}
