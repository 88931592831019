import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import PatientDetailsPage from './pages/PatientDetailsPage';
import PatientsListPage from './pages/PatientsListPage';
import PatientNewPage from './pages/PatientNewPage';
import PatientEditPage from './pages/PatientEditPage';
import AppointmentNewPage from './pages/AppointmentNewPage';
import AppointmentEditPage from './pages/AppointmentEditPage';
import AppointmentsNextPage from './pages/AppointmentsNextPage';
import LoginPage from './pages/LoginPage';

function App() {
  return (
    <Router>
      <Header />
      <div className='container'>
        <Routes>
          <Route path='/login' element={<LoginPage />} />

          <Route path='/patients/*' element={<PatientsListPage />} />
          <Route
            path='/patients/:patient_id'
            element={<PatientDetailsPage />}
          />
          <Route path='/patients/new' element={<PatientNewPage />} />
          <Route
            path='/patients/:patient_id/edit'
            element={<PatientEditPage />}
          />

          <Route path='/' element={<AppointmentsNextPage />} />

          <Route
            path='/patients/:patient_id/appointments/new'
            element={<AppointmentNewPage />}
          />
          <Route
            path='/patients/:patient_id/appointments/:appointment_id/edit'
            element={<AppointmentEditPage />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
