import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { logout } from '../integrations/Auth';
import { useState, useEffect } from 'react';

function Header({ text, bgColor, textColor }) {
  const [isLogin, setIsLogin] = useState(false);

  const headerStyles = {
    backgroundColor: bgColor,
    color: textColor,
  };

  useEffect(() => {
    if (window.location.pathname === '/login') {
      setIsLogin(true);
    }
  }, []);

  const callLogout = async () => {
    await logout();
  };

  let headerItem;
  if (isLogin) {
    headerItem = <li className='header-item'></li>;
  } else {
    headerItem = (
      <li className='header-item'>
        <Link
          to={`/login`}
          onClick={callLogout}
          style={{ textDecoration: 'none' }}
        >
          <div className='header-link'>Sair</div>
        </Link>
      </li>
    );
  }

  return (
    <header style={headerStyles}>
      <div className='header-parent'>
        <ul className='header-left'>
          <li>
            <Link to={`/`} style={{ textDecoration: 'none' }}>
              <div className='header-title'>{text}</div>
            </Link>
          </li>
        </ul>
        <ul className='header-right'>{headerItem}</ul>
      </div>
    </header>
  );
}

Header.propTypes = {
  text: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  username: PropTypes.string,
};

Header.defaultProps = {
  text: 'Clínica Seitai',
  bgColor: 'rgba(0,0,0,0.4)',
  textColor: '#fff',
  username: '',
};

export default Header;
