import PatientForm from '../components/PatientForm';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { fetchPatient } from '../integrations/Patients';
import { checkLogin } from '../integrations/Auth';

function PatientEditPage() {
  const { patient_id } = useParams();
  const [patientID] = useState(patient_id);
  const [patient, setPatient] = useState({});
  const [status, setStatus] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    async function isLoggedIn() {
      const res = await checkLogin();
      if (!res.is_authorized) {
        navigate('/login');
      }
    }
    isLoggedIn();

    async function getData() {
      const patientFromServer = await fetchPatient(patientID);
      setPatient(patientFromServer ? patientFromServer.patient : {});
      setStatus(patientFromServer.status);
    }
    getData();
  }, []);

  if (status === 404) {
    return <h1>Paciente não encontrado</h1>;
  }

  return <PatientForm patient={patient} edit={true} />;
}

export default PatientEditPage;
