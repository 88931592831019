import { useState, useEffect } from 'react';
import { getNextAppointments } from '../integrations/Appointments';
import { Link } from 'react-router-dom';

function AppointmentsNext() {
  const [appointments, setAppointments] = useState([]);
  const [keys, setKeys] = useState([]);
  const [status, setStatus] = useState(0);

  useEffect(() => {
    async function getData() {
      const appointmentsFromServer = await getNextAppointments();
      setAppointments(
        appointmentsFromServer ? appointmentsFromServer.appointments : []
      );
      setStatus(appointmentsFromServer.status);
    }
    getData();
  }, []);

  useEffect(() => {
    setKeys(Object.keys(appointments));
  }, [appointments]);

  if (status === 404) {
    return <h1>Agendamentos não encontradas</h1>;
  }

  return (
    <div>
      <h1>Próximos agendamentos</h1>
      {keys.map((key) => (
        <div key={key}>
          <div className='date-title'>
            {new Date(key).toLocaleDateString('pt-br')}
          </div>
          {appointments[key].map((appointment) => (
            <div key={appointment.id} className='appointment-summary'>
              <div className='summary-name'>
                <Link
                  to={`/patients/${appointment.patient_id}`}
                  style={{ textDecoration: 'none' }}
                >
                  {appointment.name}
                </Link>
              </div>
              <div className='summary-data'>
                <div className='summary-time'>
                  Horário:{' '}
                  {new Date(appointment.datetime).toLocaleTimeString('pt-br', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </div>
                <div className='summary-professional'>
                  {appointment.professional === ''
                    ? ''
                    : `Profissional: ${appointment.professional}`}
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}

      <Link to={`/patients`}>
        <h3 className='patients-list-btn'>Lista de Pacientes</h3>
      </Link>
    </div>
  );
}

export default AppointmentsNext;
