import AppointmentForm from '../components/AppointmentForm';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getAppointment } from '../integrations/Appointments';
import { checkLogin } from '../integrations/Auth';

function AppointmentEditPage() {
  const { patient_id, appointment_id } = useParams();
  const [patientID] = useState(patient_id);
  const [appointmentID] = useState(appointment_id);
  const [appointment, setAppointment] = useState({});
  const [status, setStatus] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    async function isLoggedIn() {
      const res = await checkLogin();
      if (!res.is_authorized) {
        navigate('/login');
      }
    }
    isLoggedIn();

    async function getData() {
      const appointmentFromServer = await getAppointment({
        patient_id: patientID,
        appointment_id: appointmentID,
      });
      setAppointment(
        appointmentFromServer ? appointmentFromServer.appointment : {}
      );
      setStatus(appointmentFromServer.status);
    }
    getData();
  }, []);

  if (status === 404) {
    return <h1>Agendamento não encontrado</h1>;
  }

  return (
    <AppointmentForm
      patient_id={patientID}
      appointment={appointment}
      edit={true}
    />
  );
}

export default AppointmentEditPage;
